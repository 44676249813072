/* eslint-disable react/display-name */
import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import { Converter } from "showdown"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils";

import TheaterVideo from "@components/Theater/TheaterVideo";
import Button from '@bit/azheng.joshua-tree.button';
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

import SideCTA from "../components/CTA/SideCTA"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const SingleInstruction = ({ data, pageContext, location }) => {
 
  var post;
  var language;
  let postObj = getPostObj(pageContext, data.allInstructionsJson.nodes[0], data.allSpanishInstructionsJson.nodes[0]);
  post = postObj.post;
  language = postObj.language;

  return (
    <SharedStateProvider>
      <Layout className={`single-instruction ${language === "es" ? "es" : ""}`} language={"en"}>
      <SEO 
        title={post.metaTitle} 
        description={post.metaDescription} 
        pathname={location.pathname} 
        schemaPage={post.schemaPage ? post.schemaPage : null} 
        youtubeSchemas={pageContext.youtubeSchemasJson}
      />
        <div className="columns top-section color-back">
          <div className="column is-4"></div>
          <div className="column">
            <h1 style={{marginTop: 0, marginBottom: 0}}>
              {post.name === "Post-Operative Instructions: DIEM® Full-Arch Replacement"
                ? <>Post-Operative Instructions: DIEM<sup>®</sup> Full-Arch Replacement</>
                : post.name
              }
            </h1>
            <Button
              buttonText={language === "es" ? "ATRÁS" : "BACK"}
              goBack
              href={
                language === "es"
                  ? "/instructions/"
                  : "/instructions/"
              }
            />
          </div>
          <div className="column is-4"></div>
        </div>
        <div className="body-section joshua-tree-content instruction-content">
          {post.youtube && post.mainPhotoPublicId && (
            <div className="columns instruction-image-video">
              <div className="column is-3"></div>
              <div className="column">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  overVideo={true}
                  language={language}
                  buttonClass="contained"
                >
                  <NuvoImage
                    useAR
                    cloudName="nuvolum"
                    publicId={post.mainPhotoPublicId}
                    
                    width="auto"
                    className="theater-image"
                  >
                    
                  </NuvoImage>
                </TheaterVideo>
              </div>
              <div className="column is-3"></div>
            </div>
          )}
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <MarkdownViewer className="p-with-ul single-instructions-ul" markdown={post.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column is-6">
              
              {language === "es"
                ? <></>
                : <SideCTA
                    className="single-instruction-questions"
                    sideColumnIs={0}
                    subheading={language === "es" ? "Preguntas?" : "Questions?"}
                    heading={
                      language === "es"
                        ? "Estamos Aquí Para Ayudar"
                        : "We Are Here To Help"
                    }
                    paragraph={
                      language === "es"
                        ? "Póngase en contacto con nuestra oficina si tiene alguna pregunta o problema con estas instrucciones."
                        : "Please contact our office if you have any questions or issues with these instructions."
                    }
                    buttonText={language === "es" ? "Contáctenos" : "Contact Us"}
                    buttonUrl={
                      language === "es"
                        ? "/es/comuniquese-con-nosotros/"
                        : "/contact-us/"
                    }
                  />
              }
              
              <div class="is-hidden-mobile" style={{height: 30}}></div>
{ post.pdfId &&             
              <SideCTA
                className=""
                sideColumnIs={0}
                subheading={
                  language === "es" ? "Versión PDF" : "PDF Version"
                }
                heading={
                  language === "es"
                    ? "Instrucciones de Descarga"
                    : "Download Instructions"
                }
                paragraph={
                  language === "es"
                    ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                    : "Take the instructions on the go. Download in PDF format."
                }
                buttonText={language === "es" ? "Descargar" : "Download"}
                buttonUrl={`https://res.cloudinary.com/nuvolum/image/upload/v1578872461/${post.pdfId}.pdf`}
                download
              />
}
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query($title: String!) {
    allUniquePagesJson(filter: {template: {eq: "patient-instructions"}}) {
      nodes {
        title
      }
    }
    allInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        schemaPage
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
      }
    }
    allSpanishInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        schemaPage
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
        language
      }
    }
  }
`

export default SingleInstruction
